import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { SSOService } from '../../../services/sso.service';
import { UserInfoService } from '../../../services/user-info.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { TranslocoService } from "@jsverse/transloco";

@Component({
  selector: 'app-benchmark',
  templateUrl: './benchmark-container.component.html',
  styleUrls: ['./benchmark-container.component.scss'],
})
export class BenchmarkContainerComponent implements OnInit {

  showElement = false;

  constructor(
    public ssoService: SSOService,
    private userInfoService: UserInfoService,
    public translocoService: TranslocoService
  ) { }

  ngOnInit() {
    this.ssoService.ssoToken().subscribe((response) => {
      if (response && !(response instanceof HttpErrorResponse)) {
        this.userInfoService.authTokenSubject$.subscribe((res) => {
          this.getHostAppLang(res.authToken);
        });
      } else {
        this.getHostAppLang('No Token');
      }
    });
  }

  getHostAppLang(token) {
    this.translocoService.langChanges$.subscribe((res) => {
      this.showElement = false;
      setTimeout(() => {
        this.setupWc(token, res);
      }, 100);
      })
  }

  setupWc(arg, hostAppLang?) {
    this.showElement = true;
    setTimeout(() => {
      if (arg && arg !== 'No Token') {
        arg = arg.split('Bearer ').pop();
      }
      if (document.querySelector('sisense-sdk-element') !== null) {
        const element = document.querySelector('sisense-sdk-element');
        element.setAttribute('token', arg);
        element.setAttribute(
          'config',
          JSON.stringify({
            sisenseUrl: environment.appConfig.sisenseUrl,
            insightsTenantManagementUrl: environment.appConfig.insightsTenantManagementUrl,
            language: hostAppLang
          })
        );
      }
    }, 100);
  }

  renewToken(e?) {
    this.ssoService.ssoToken().subscribe((res) => {
      this.ssoService.renewAuthToken(res).subscribe((res) => {
        this.userInfoService.setAuthToken(res);
        this.setupWc(res);
      });
    })
  }
}
