import { IAppConfig } from './IAppConfig';

export const APP_CONFIG: IAppConfig = {
  rafaUrl: 'https://banking.moodysanalytics.com/',
  rafaFedRedirectUrl: 'https://banking.moodysanalytics.com/federatedlogin?',
  rafaLegRedirectUrl: 'https://banking.moodysanalytics.com/login?',
  environmentName: 'Production',
  cookieDomain: '.moodysanalytics.com',
  endpointVersion: '/1.0',
  ssoServiceEndpoint: 'https://sso.moodysanalytics.com/sso-api',
  multiEnvEndpoint: 'https://sso.moodysanalytics.com/entitlement',
  inactivityTimeout: 3600, // In seconds
  passwordReset: 'https://accounts.moodysanalytics.com/login',
  helpUrl: 'https://accounts.moodysanalytics.com/help',
  walkMeUrl: 'https://cdn.walkme.com/users/e451ae027c83467bb6cb3b559a24c29e/walkme_e451ae027c83467bb6cb3b559a24c29e_https.js',
  tokenRefreshInterval: 840000,//In milliseconds -token renews every 14 minutes
  sisenseUrl: 'https://sisense.rafa.moodysanalytics.com',
  insightsTenantManagementUrl: 'https://api.data.moodysanalytics.com/insights-tenant-management/v1',
  staticContent: 'https://webcomponents.moodysanalytics.com/images',
  webcomponents: 'https://webcomponents.moodysanalytics.com/',
  contactUsUrl: 'http://www.moodysanalytics.com/Contact-Us',
  bankingPortalEndpoint: {
    name: 'BankinPortal',
    url: 'https://banking.moodysanalytics.com/',
    visibleInMenu: false
  },
  adminPortal: {
    availableFeature: true,
    roles: [{ name: 'RafaAdmin' }, { name: 'CapSolution' }, { name: 'ImpairmentStudio' }, { name: 'CapitalRiskAnalyzer' }, { name: 'CapSolutionAdmin' }, { name: 'cra_cap_model' }, { name: 'CapDominoSupport' }],
    entitlements: [{ name: 'RiskCalc' }, { name: 'CMM' }, { name: 'CapSolution' }, { name: 'RiskBench' }, { name: 'ImpairmentStudio' }, { name: 'ImpairmentCalc' }, { name: 'CapitalRiskAnalyzer' }]
  },
  drawerGetWay: 'https://www.moodys.com/mfe/mdc-gateway-drawer/build/mdc-gateway-drawer.js',
  apiHub: 'https://hub.moodysanalytics.com/',
  appCenter: 'https://apps.moodysanalytics.com/home',
  dataAlliance: 'https://dataalliance.moodysanalytics.com/#/',
  economyDotCom: 'https://www.economy.com/myeconomy'
};