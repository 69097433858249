<div *transloco="let t">
<div class="footer-container">
  <div class="moodys-logo">
    <a href="https://www.moodysanalytics.com/"><img class="logo-footer"
           src="{{localEnvironment.appConfig.staticContent}}/logos/MoodysLogoWhite-2024.svg"
           alt="Moody's Analytics" /></a>
  </div>
  <div class="social-media">
    <a href="https://www.linkedin.com/company/moodysanalytics/"
       target="_blank">
      <img class="social-logos" src="{{localEnvironment.appConfig.staticContent}}/logos/linkedin.svg"
           alt="LinkedIn" />
    </a>
    <a href="https://twitter.com/MoodysAnalytics"
       target="_blank">
      <img class="social-logos" src="{{localEnvironment.appConfig.staticContent}}/logos/x-logo.svg"
           alt="Twitter" />
    </a>
  </div>
  <hr />
  <div class="copyright">&copy; {{currentYear}} {{ t('moodysAnalyticsInc') }}
  </div>
  <ul class="links">
    <li>
      <a href="https://www.moodysanalytics.com/product-support"
         target="_blank">{{ t('productSupport') }}</a>
    </li>
    <li>
      <a href="https://www.moodysanalytics.com/about-us/history"
         target="_blank">{{ t('aboutUs') }}</a>
    </li>
    <li>
      <a href="https://www.moodys.com/privatepolicy.aspx?lang=en&cy=global"
         target="_blank">{{ t('privacyPolicy') }}</a>
    </li>
    <li>
      <a href="https://www.moodys.com/termsofuseinfo.aspx?lang=en&cy=global"
         target="_blank">{{ t('termsOfUse') }}</a>
    </li>
    <li>
      <a href="https://www.moodysanalytics.com/"
         target="_blank">{{ t('learnMore') }}</a>
    </li>
    <li>
      <a href="{{localEnvironment.appConfig.staticContent}}/documents/Banking_Portal_Third_Party_Components_2023.pdf"
         target="_blank">{{ t('thirdPartyComponents') }}</a>
    </li>
  </ul>
</div>