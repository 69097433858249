<div *transloco="let t">
<div class="return-to-app-container">
  <div class="return-to-app">
    <a (click)="goBackToProduct()">
      <mat-icon class="back-to-app-icon">arrow_back</mat-icon>{{ t('back') }}</a>
  </div>
</div>
<div class="change-password-container">
  <div class="title">{{ t('changePassword') }}</div>
  <div class="change-password-form-container">
    <div class="change-password-form">
      <div class="change-password-form-title">{{ t('changePasswordFor') }} {{userName}}</div>
      <form [formGroup]="changePasswordForm">
        <mat-form-field class="account-setting-input"
                        color="accent">
          <input matInput
                 id="current-password"
                 placeholder="{{ t('currentPassword') }}"
                 [type]="showPasswords ? 'text' : 'password'"
                 formControlName="currentPassword"
                 [formGroup]="changePasswordForm">
          <mat-icon matSuffix
                    class="good-password-color password-validity-icon"
                    *ngIf="currentPasswordCtrl.valid">done</mat-icon>
          <mat-error *ngIf="currentPasswordCtrl.invalid && (currentPasswordCtrl.dirty || currentPasswordCtrl.touched)">{{ t('theCurrentPasswordIsRequired') }}</mat-error>
        </mat-form-field>
        <mat-form-field class="account-setting-input"
                        color="accent">
          <input matInput
                 id="new-password"
                 placeholder="{{ t('newPassword') }}"
                 [type]="showPasswords ? 'text' : 'password'"
                 formControlName="newPassword"
                 [formGroup]="changePasswordForm">
          <mat-icon matSuffix
                    class="good-password-color password-validity-icon"
                    *ngIf="newPasswordCtrl.valid && changePasswordForm.value.currentPassword !== changePasswordForm.value.newPassword"> done</mat-icon>
          <mat-error *ngIf="newPasswordCtrl.errors
                                && newPasswordCtrl.errors.PasswordRestriction
                                && (newPasswordCtrl.dirty || newPasswordCtrl.touched)">{{ t('yourNewPassword') }}</mat-error>
        </mat-form-field>
        <mat-form-field class="account-setting-input"
                        color="accent">
          <input matInput
                 id="confirm-new-password"
                 placeholder="{{ t('confirmNewPassword') }}"
                 [type]="showPasswords ? 'text' : 'password'"
                 formControlName="confirmNewPassword"
                 [formGroup]="changePasswordForm">
          <mat-icon matSuffix
                    class="good-password-color password-validity-icon"
                    *ngIf="confirmNewPasswordCtrl.valid && changePasswordForm.value.currentPassword !== changePasswordForm.value.newPassword">done </mat-icon>
          <mat-error
                     *ngIf="confirmNewPasswordCtrl.errors && confirmNewPasswordCtrl.errors.MatchPassword && (confirmNewPasswordCtrl.dirty || confirmNewPasswordCtrl.touched)">{{ t('thisFieldMustMatch') }}</mat-error>
        </mat-form-field>
        <div class="show-passwords-container">
          <a class="show-passwords-link"
             (click)="showPasswordsToggle()">
            <mat-icon class="show-passwords-icon secondary-text-color"> visibility</mat-icon>
            <span class="show-passwords-text"
                  [innerHTML]="showPasswords ? 'Hide passwords' : 'Show passwords'"></span>
          </a>
        </div>
        <div class="change-password-buttons">
          <button mat-raised-button
                  type="submit"
                  color="accent"
                  (click)="savePassword()"
                  [formGroup]="changePasswordForm"
                  [disabled]="changePasswordForm.invalid || federatedUser || changePasswordForm.value.currentPassword == changePasswordForm.value.newPassword">{{ t('changePassword') }}</button>
          <button mat-button
                  class="cancel"
                  type="submit"
                  (click)="cancelPassword()"
                  [formGroup]="changePasswordForm"
                  [disabled]="!changePasswordForm.dirty && !showPasswords">{{ t('cancel') }}</button>
        </div>
      </form>
    </div>
    <div class="password-restrictions">
      <p class="password-restrictions-title">{{ t('passwordRequirements') }}</p>
      <ul class="password-restrictions-list">
        <li>{{ t('atLeastEightCharacters') }}</li>
        <li>{{ t('atLeastOneLowercaseLetter')}}</li>
        <li>{{ t('atLeastOneUppercaseLetter')}}</li>
        <li>{{ t('atLeastOneNumber')}}</li>
        <li>{{ t('mustNotBe')}}</li>
        <li>{{ t('cannotBe')}}</li>
      </ul>
    </div>
  </div>
</div>
</div>
