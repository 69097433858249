import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable, isDevMode } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatExpansionModule } from "@angular/material/expansion";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { MainNavComponent } from "./components/navigation/main/main-nav.component";
import { FooterComponent } from "./components/navigation/footer/footer.component";
import { LandingComponent } from "./components/content/landing/landing.component";
import { LoginComponent } from "./components/loading/login/login.component";
import { FederatedLoginComponent } from "./components/loading/login/federated-login.component";
import { LogoutComponent } from "./components/loading/logout/logout.component";
import { AccountSettingsComponent } from "./components/content/account/account-settings.component";
import { BlockerComponent } from "./components/loading/blocker/blocker.component";
import "./animations/animations";

import { SSOService } from "./services/sso.service";
import { UserInfoService } from "./services/user-info.service";
import { AnimationTriggerService } from "./services/animation-trigger.service";
import { BlockerService } from "./services/blocker.service";
import { ProjectWorkspaceService } from "./services/project-workspace.service";
import { NotificationService } from "./services/notifications.service";
import { MyapplicationsComponent } from "./components/adminportal/myapplications/myapplications.component";
import { BenchmarkContainerComponent } from "./components/benchmark/benchmark-container/benchmark-container.component";
import { NotFoundComponent } from "./components/content/not-found/not-found.component";
import { ScriptsService } from "../app/scripts/scripts.service";
import { ProductLinksComponent } from './components/navigation/product-links/product-links.component';
import { AdditionalresourcesComponent } from './components/adminportal/myapplications/additionalresources/additionalresources.component';
import { MyapplicationslistComponent } from './components/adminportal/myapplications/myapplicationslist/myapplicationslist.component';
import { AdditionalresourcecardComponent } from './components/adminportal/myapplications/additionalresources/additionalresourcecard/additionalresourcecard.component';
import { UsageComponent } from './components/usage/usage.component';

import { provideTransloco, TranslocoModule } from '@jsverse/transloco';
import { TranslocoHttpLoader } from './transloco-loader';



const routes: Routes = [
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "federatedlogin",
    component: FederatedLoginComponent
  },
  {
    path: "logout",
    component: LogoutComponent
  },
  {
    path: "federatedlogout",
    component: LogoutComponent
  },
  {
    path: "account",
    component: AccountSettingsComponent,
    data: { animation: "accountPage" },
  },
  { path: "riskcalc", redirectTo: '/solutions' },
  { path: "cmm", redirectTo: '/solutions' },
  { path: "riskbench", redirectTo: '/solutions' },
  { path: "cap", redirectTo: '/solutions' },
  { path: "impairmentstudio", redirectTo: '/solutions' },
  { path: "impairmentcalc", redirectTo: '/solutions' },
  { path: "cra", redirectTo: '/solutions' },
  { path: "riskfrontier", redirectTo: '/solutions' },
  { path: "creditedge", redirectTo: '/solutions' },
  { path: "nextgen-pd", redirectTo: '/solutions' },
  { path: "portfoliostudio", redirectTo: '/solutions' },
  { path: "bankingcloud", redirectTo: '/solutions' },
  { path: "onlinebudgeting", redirectTo: '/solutions' },
  { path: "alm", redirectTo: '/solutions' },
  { path: "onlinececl", redirectTo: '/solutions' },
  { path: "rpa", redirectTo: '/solutions' },
  { path: "my-applications", redirectTo: '/solutions' },
  { path: "applications", redirectTo: '/solutions' },
  { path: "benchmark", component: BenchmarkContainerComponent  },
  { path: "solutions", component: MyapplicationsComponent },
  // { path: "usage", component: UsageComponent },
  { path: "", component: LandingComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    FooterComponent,
    LandingComponent,
    LoginComponent,
    LogoutComponent,
    AccountSettingsComponent,
    BlockerComponent,
    FederatedLoginComponent,
    MyapplicationsComponent,
    BenchmarkContainerComponent,
    NotFoundComponent,
    ProductLinksComponent,
    AdditionalresourcesComponent,
    MyapplicationslistComponent,
    AdditionalresourcecardComponent,
    UsageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      closeButton: true,
      positionClass: "toast-top-right",
    }),
    MatToolbarModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTableModule,
    MatInputModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSortModule,
    MatListModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSortModule,
    MatDialogModule,
    MatSelectModule,
    TranslocoModule
  ],
  exports: [TranslocoModule],
  providers: [
    UserInfoService,
    SSOService,
    AnimationTriggerService,
    BlockerService,
    ProjectWorkspaceService,
    NotificationService,
    ScriptsService,
    TranslocoHttpLoader,
    provideTransloco({
      config: {
        availableLangs: ['en', 'es', 'fr', 'nl', 'zh', 'it', 'de'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
