<div *transloco="let t">
    <div class="solutions-header-container">
        <div class="title">{{ t('mySolutions') }}</div>
        <!-- <button mat-stroked-button color="primary" class="usage-button" (click)="goToUsage()">
        <span class="material-symbols-outlined" style="margin: 0px 8px 0px -4px;">monitoring</span>Data Usage </button> -->
    </div>
    <div class="container"
         *ngIf="list?.length > 0">
        <div class="item-container"
             *ngFor="let product of list, let i=index">
            <div class="image-block"> <img
                     src="{{localEnvironment.appConfig.staticContent}}/product-icons/{{product.productImage}}"
                     alt=""></div>
            <div class="text-block">
                <div class="display-name"><a href="{{product.selectedEnvWebLink}}">{{product.displayName}}</a></div>
                <div class="product-table">
                    <div *ngIf="product.environments.length > 0">
                        <mat-form-field>
                            <mat-select [disabled]="product.environments.length === 1"
                                        (selectionChange)="onselectChange($event.value, i)"
                                        [(value)]="product.selectedEnv">
                                <mat-option *ngFor="let org of product.environments"
                                            [value]="org.env_name"> {{org.env_name}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="btn">
                    <a class="app-link"
                       href="{{product.selectedEnvWebLink}}">{{ t('goToTheApp') }}</a>
                </div>
                <div>
                    <button mat-stroked-button
                            class="doc-btn"
                            (click)="goToDocumentation(product.documentationUrl)">
                        <mat-icon class="material-icons-outlined"> description </mat-icon>{{ t('goToDocumentation') }}</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="list?.length == 0"
         class="no-apps-found">
        <div>{{ t('wantToLearnMore') }}</div>
        <button mat-raised-button
                (click)="onContactusClick()"
                class="btn-contactus">{{ t('contactUs') }}</button>
    </div>
</div>
