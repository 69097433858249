import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from "../../../../../environments/environment";
import { Router } from '@angular/router';

@Component({
  selector: 'app-myapplicationslist',
  templateUrl: './myapplicationslist.component.html',
  styleUrls: ['./myapplicationslist.component.scss']
})
export class MyapplicationslistComponent implements OnInit {
  localEnvironment;
  @Input() list;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  @Output() notifyParentDocUrl: EventEmitter<any> = new EventEmitter();
  @Output() notifyParentContactus: EventEmitter<any> = new EventEmitter();
  constructor(
    public router: Router
  ) { 
    this.localEnvironment = environment; 
  }
  ngOnInit(): void {}

  goToUsage() {
    this.router.navigate(['./usage']);
  }
  goToApp(url) {
    this.notifyParent.emit(url);
  }
  goToDocumentation(docurl) {
    this.notifyParentDocUrl.emit(docurl);
  }
  onselectChange(value, i){
    const selectedEnv = this.list[i].environments.find(env => env.env_name === value);
    this.list[i].selectedEnvWebLink = selectedEnv.web_link;
  }
  onContactusClick(){
    this.notifyParentContactus.emit();
  }
}
