<div *transloco="let t">
  <div class="yellow-banner">
    <span class="yellow-banner-text"><b style="font-weight: bold;">IMPORTANT!</b> The widely-publicized CrowdStrike incident and
      related global technology disruptions have resulted in operational challenges for some of our customers.<br>Therefore, we
      have made the decision to postpone our SSO production rollout from July 20th to July 27th, 2024. <br>If you have any
      questions, please contact Moody’s client services at ma_support@moodys.com.</span>
  </div>
  <div class="landing-jumbotron">
    <div class="landing-jumbotron-container">
      <div class="landing-message-container">
        <div class="landing-text-container">
          <div *ngIf="!isLoggedIn"
               class="landing-title"
               [innerHTML]="pageContentData.jumbotron.title"></div>
          <div style="width:auto;"
               *ngIf="!isLoggedIn; else loggedIn">
            <div class="landing-login-text"
                 [innerHTML]="pageContentData.jumbotron.notLoggedInTitle"></div>
            <button mat-raised-button
                    id="login-home"
                    (click)="initiateLogin()"
                    color="accent"
                    class="content-button">Log in</button>
          </div>
          <ng-template #loggedIn>
            <div class="landing-authenticated-text">{{ t('youAreLoggedInAs') }}<span>{{ userName }}</span>
            </div>
            <button mat-raised-button
                    id="logout-home"
                    (click)="initiateLogout()"
                    color="accent"
                    class="content-button">{{ t('logOut') }}</button>
          </ng-template>
        </div>
      </div>
      <!-- <div class="landing-video">
      <div>
        <iframe frameborder="0"
                src="https://player.vimeo.com/video/355349238?autoplay=1&amp;loop=1;muted=1;title=0"></iframe>
      </div>
    </div> -->
    </div>
  </div>
  <div class="blue-banner">
    <span class="blue-banner-text">{{ t('aBoldEvolution') }}</span>
    <button mat-stroked-button
            onClick="window.open('https://brandcenter.moodys.com/account/dashboard','_blank')"
            class="blue-banner-button"><span style="padding: 0px 16px 0px 16px">{{ t('learnMore') }}</span></button>
  </div>
  <app-product-links></app-product-links>
</div>
