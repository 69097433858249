<div *transloco="let t">
    <div class="container">
        <!-- <div class="block-container"
         *ngFor="let item of list">
        <div class="item-container">
            <div class="image-block"> <img src="{{localEnvironment.appConfig.staticContent}}/product-icons/{{item.productImage}}"
                     alt=""></div>
            <div class="text-block">
                <div class="display-name"><a target="_self"
                       href="{{item.displayNameUrl}}">{{item.displayName}}</a></div>
                <div class="description">{{item.description}}</div>
                <a target="_self"
                   class="documnetation-url"
                   href="{{item.appurl}}">{{item.appurlDisplayName}}</a>
            </div>
        </div>
    </div> -->
        <div class="block-container">
            <div class="item-container">
                <div class="image-block"> <img src="{{localEnvironment.appConfig.staticContent}}/product-icons/API-Hub-2024.svg"
                         alt=""></div>
                <div class="text-block">
                    <div class="display-name"><a target="_self"
                           href="{{localEnvironment.appConfig.apiHub}}">API Hub</a></div>
                    <div class="description">{{ t('apiHubDescription') }}</div>
                    <a target="_self"
                       class="documnetation-url"
                       href="{{localEnvironment.appConfig.apiHub}}">{{ t('goToThe') }} API Hub</a>
                </div>
            </div>
            <div class="item-container">
                <div class="image-block"> <img
                         src="{{localEnvironment.appConfig.staticContent}}/product-icons/App-Center-2024.svg"
                         alt=""></div>
                <div class="text-block">
                    <div class="display-name"><a target="_self"
                           href="{{localEnvironment.appConfig.appCenter}}">App Center</a></div>
                    <div class="description">{{ t('appCenterDescription') }}</div>
                    <a target="_self"
                       class="documnetation-url"
                       href="{{localEnvironment.appConfig.appCenter}}">{{ t('goToThe') }} App Center</a>
                </div>
            </div>
            <div class="item-container">
                <div class="image-block"> <img
                         src="{{localEnvironment.appConfig.staticContent}}/product-icons/Data-Alliance-2024.svg"
                         alt=""></div>
                <div class="text-block">
                    <div class="display-name"><a target="_self"
                           href="{{localEnvironment.appConfig.dataAlliance}}">Data Alliance</a></div>
                    <div class="description">{{ t('dataAllianceDescription') }}</div>
                    <a target="_self"
                       class="documnetation-url"
                       href="{{localEnvironment.appConfig.dataAlliance}}">{{ t('goToThe') }} Data Alliance</a>
                </div>
            </div>
            <div class="item-container">
                <div class="image-block"> <img src="{{localEnvironment.appConfig.staticContent}}/product-icons/Economy-2024.svg"
                         alt=""></div>
                <div class="text-block">
                    <div class="display-name"><a target="_self"
                           href="{{localEnvironment.appConfig.economyDotCom}}">Economy.com</a></div>
                    <div class="description">{{ t('economyDotComDescription') }}</div>
                    <a target="_self"
                       class="documnetation-url"
                       href="{{localEnvironment.appConfig.economyDotCom}}">{{ t('goToThe') }} Economy.com</a>
                </div>
            </div>
        </div>
    </div>
</div>
