import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Utility } from '../util/utility';
import { environment } from '../../environments/environment';
import { jwtDecode } from 'jwt-decode';
import { NotificationService } from '../services/notifications.service';
import { Router, ActivatedRoute } from "@angular/router";
declare var heap: any;


@Injectable()
export class UserInfoService {
  public authTokenSubject$ = new BehaviorSubject<{ authToken: string; decodedToken: string }>({
    authToken: undefined,
    decodedToken: undefined
  });
  public readonly authToken$ = this.authTokenSubject$.asObservable();
  private authToken: string;
  public _decodedToken: any;

  private forceResetPassword = false;
  validDomains = ['moodysanalytics.com', 'moodysanalytics.net', 'analytics.moodys.net', 'moodyskmv.com'];
  localEnv = environment;
  /**
   * Get the decodedToken as a JSON object
   * If authToken is undefined, decodedToken will also be undefined.
   */
  public get decodedToken(): any {
    return this._decodedToken;
  }
  private setDecodedToken(tokenObj: any) {
    this._decodedToken = tokenObj;
  }
  /**
   * Decode Auth Token into JSON and store into decodedToken
   */
  private decodeAuthToken(): void {
    if (this.authToken) {
      try {
        const decodedToken = jwtDecode(this.authToken);
        this.setDecodedToken(decodedToken);
      }
      catch (err) {
        this.notification.showError(err, 'Cannot decode the token, please get in touch with us');
        this.router.navigate(["./"]);
      }
    } else {
      this.setDecodedToken(null);
    }
  }

  constructor(
    private notification: NotificationService,
    private router: Router
  ) { }

  /**************************************
        CRUD METHODS
    **************************************/

  /*Get User's Authorization Token*/
  public getAuthToken(): string {
    return this.authToken;
  }

  /*Set User's Authorization Token*/
  public setAuthToken(newAuthToken: string): void {
    this.authToken = newAuthToken;
    this.decodeAuthToken();
    this.authTokenSubject$.next({ authToken: this.authToken, decodedToken: this.decodedToken });

  }

  /*Check User's Authorization Token is valid*/
  public validateAuthToken(): boolean {
    const authToken = this.getAuthToken();
    let expiryEpoch;

    if (authToken && this.decodedToken) {
      // get the epoch time of when the token expires
      expiryEpoch = this.decodedToken.exp;
      if (expiryEpoch) {
        // get the current epoch time
        const currentEpoch = Math.round(new Date().getTime() / 1000);
        // if the token has not expired and will not expire for another 60 seconds then it is valid
        if (expiryEpoch > currentEpoch + 60) {
          return true;
        }
      }
    }
    // in all other cases the token is not valid
    return false;
  }

  public isTokenAboutToExpire() {
    const authToken = this.getAuthToken();
    let expiryEpoch;
    if (authToken && this.decodedToken) {
      // get the epoch time of when the token expires
      expiryEpoch = this.decodedToken.exp;
      if (expiryEpoch) {
        // get the current epoch time
        const currentEpoch = Math.round(new Date().getTime() / 1000);
        // if the token is expiring in another 120 seconds
        if (expiryEpoch - currentEpoch < 120) {
          return true;
        }
      }
    }
    // in all other cases the token is not expiring in 2 minutes
    return false;
  }

  /*Clear all User information/settings*/
  public clearUserInfo(): void {
    this.setAuthToken(null);
    if (localStorage.getItem('app-url') === null) {
      localStorage.clear();
    }
  }
  public clearLocalCache(): void {
    this.setAuthToken(null);
    localStorage.clear();
  }
  /*Get if User should be forced to change password*/
  public getForceResetPassword(): boolean {
    return this.forceResetPassword;
  }

  /*Set if User should be forced to change password*/
  public setForceResetPassword(resetPassword: boolean): void {
    this.forceResetPassword = resetPassword;
  }

  public getUserId(): string {
    if (this.decodedToken) {
      return this.decodedToken.userid;
    }
  }

  /*Get User's username and username with data store*/
  public getUserName(): string {
    if (this.decodedToken) {
      let subValue = this.decodedToken ? this.decodedToken.sub : undefined;
      if (subValue && subValue.indexOf('@carbon.super') >= 0) {
        subValue = subValue.replace('@carbon.super', ' ');
      }
      if (subValue && subValue.indexOf('/') >= 0) {
        const array = subValue.split('/');
        subValue = array[1];
      }
      return subValue;
    }
  }

  public getAccountSfdcId(): string {
    if (this.decodedToken) {
      return this.decodedToken.accountsfdcid;
    }
  }

  public addHeapIdentifyAPI() {
    heap.identify(this.decodedToken.email);
    heap.addUserProperties({
      'Organization ID': this.decodedToken.organization,
      'Organization Name': this.decodedToken.org_name,
      'SFDC User ID': this.decodedToken.contactid
    });
    this.passUserInfoToWalkMe();
  }

  passUserInfoToWalkMe() {
    let moodysVariables = {
      loginUserId: this.decodedToken.email,
      organizationId: this.decodedToken.organization,
      organizationName: this.decodedToken.org_name,
      sfdcUserId: this.decodedToken.contactid
    }
    window['moodys_variables'] = moodysVariables;
  }

  /*Get User's first name*/
  public getFirstName(): string {
    if (this.decodedToken) {
      return this.decodedToken.first_name;
    }
  }

  /*Get User's last name*/
  public getLastName(): string {
    if (this.decodedToken) {
      return this.decodedToken.family_name;
    }
  }

  public getGivenName(): string {
    if (this.decodedToken) {
      return this.decodedToken.given_name;
    }
  }

  public getUserFederatedAccountInfo(): boolean {
    if (this.decodedToken) {
      return this.decodedToken.federatedAccount ? true : false;
    }
  }

  public isMAToken(): boolean {
    const authToken = this.getAuthToken();
    return authToken && this.decodedToken && this.decodedToken.tokentype === undefined;
  }

  public getEntitlements(): string {
    if (this.isMAToken()) {
      // is MA token
      return this.decodedToken.applicationentitlement;
    } else {
      if (this.decodedToken.applicationentitlement && this.decodedToken.applicationentitlement !== '[]') {
        // is old SSO token with applicationentitlements
        return this.decodedToken.applicationentitlement;
      } else {
        // is old SSO with only roles available
        return this.decodedToken.roles;
      }
    }
  }

  public isRafaAdmin() {
    if (this.decodedToken && this.decodedToken.roles) {
      if (this.decodedToken.roles.includes('MA/RafaAdmin') || this.decodedToken.roles.includes('RafaAdmin')) {
        return true;
      } else {
        return false;
      }
    }
  }

  public isDomainValid(referrerurl: string): Boolean {
    return this.validDomains.some(domain => referrerurl.endsWith(domain) || referrerurl.indexOf(domain + '/') > 1 || referrerurl.indexOf(domain + '?') > 1);
  }

  checkForDomain(productReferrerUrl, defaultProductEndpoint?) {
    if (this.isDomainValid(productReferrerUrl)) {
      window.location.href = productReferrerUrl;
    } else {
      this.notification.showError(productReferrerUrl, 'is not a valid domain, please contact us');
      window.location.href = defaultProductEndpoint;
    }
  }

  getOrgName() {
    return { companyName: this.decodedToken.organization }
  }

}
function ObservableEmpty(): any {
  throw new Error('Function not implemented.');
}


